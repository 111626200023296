import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from "axios";

// @mui
import { 
  Stack,
  Button,
  Container,
  Typography,
  Breadcrumbs, 
  IconButton,
  Card,
  Box,
 } from '@mui/material';

// hooks
import useSettings from '../../hooks/useSettings';

// components
import Page from '../../components/Page';
import { useEffect } from "react";
import { useState } from "react";
import { createProgram } from 'typescript';

// ----------------------------------------------------------------------

const CreateProgram = () => {
  const { themeStretch } = useSettings();
  // const [rows, setRows] = useState([]);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const ActionBtn = (id) =>{
    return <Stack direction="row" alignItems="center" spacing={1}>
    <IconButton aria-label="edit" size="medium" onClick={() => handleEdit(id)}>
        <EditIcon fontSize="inherit" />
    </IconButton>
    </Stack>
  };

  const handleEdit = (id) => {
    navigate('/dashboard/editprogramforms', {state:{id}});
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 150 },
    {
      field: 'program_name',
      headerName: 'Program Name',
      width: 750,
    },

    
    {
        field: 'actions',
        headerName: 'Assign/Edit Forms to Program',
        width: 260,
        renderCell: (params) => {
         let id = params.row.id
            return (
                    ActionBtn(id)
            );
         }
      },
  ];

  useEffect(() => {
    fetch('https://test3.dstinnovate.com/program')
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setRows(data);
      });
  }, []);

  return (
    <>
    <Helmet>
        <title> Create Program | Kaizen Digital </title>
      </Helmet>
    <Page title="Page Two">
      <Container maxWidth={themeStretch ? false : 'xl'}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="column" spacing={0}>
          <Typography variant="h3" gutterBottom color="primary">
            Create Program
          </Typography>
          <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Typography 
        sx={{ display: 'flex', alignItems: 'center' }}
        color="text.primary">
          Dashboard
        </Typography>

        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
Program        </Typography>
<Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
Create Programs</Typography>
      </Breadcrumbs>
    </div>
     </Stack> 
     <Stack direction="row" spacing={2}>
              <Link to="/dashboard/programlist">
                <Button
                  type="submit"
                  variant="contained"
                  spacing={2}
                  sx={{ color: "white" }}
                >
                  Create New Program
                </Button>
              </Link>
            </Stack>
          </Stack>


     <Stack direction="row" spacing={2}>

          <Link to="/dashboard/editprogramforms" >
          {/* <Button variant="contained" spacing={2}  >
            Edit Assigned Forms 
          </Button> */}
          </Link>        
        </Stack>
        <Card>
        <Box sx={{ width: '100%' }}>
      <DataGrid
      autoHeight
        slots={{ toolbar: GridToolbar }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        // checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
    </Card> 

      </Container>
    </Page>
    </>
  );
}
export default CreateProgram